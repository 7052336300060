import { getAuth } from "firebase/auth";
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/database'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDtqEdGVuGq_EICkCGihDVFbnqZJW9Jawk",
  authDomain: "bmmasistencia.firebaseapp.com",
  databaseURL: "https://bmmasistencia-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bmmasistencia",
  storageBucket: "bmmasistencia.appspot.com",
  messagingSenderId: "83316876257",
  appId: "1:83316876257:web:cd0eb3ef3a1049695e68a0",
  measurementId: "G-X2LCJF44F8"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
export const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = firebase.firestore()
export const realtime = firebase.database()
export const functions = app.functions()
