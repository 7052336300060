import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Alert } from "./Alert";

export function Login() {
	const [currentUser, setCurrentUser] = useState({
		email: "",
		password: ""
	});
	const { login, loginWithGoogle, resetPassword } = useAuth();
	const [error, setError] = useState("");
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setError("");
		try {
			await login(currentUser.email, currentUser.password);
			navigate("/");
		} catch (error) {
			setError(error.message);
		}
	};

	const handleChange = ({ target: { value, name } }) => setCurrentUser({ ...currentUser, [name]: value });


	const handleResetPassword = async (e) => {
		e.preventDefault();
		if (!currentUser.email) return setError("Write an email to reset password");
		try {
			await resetPassword(currentUser.email);
			setError("We sent you an email. Check your inbox");
		} catch (error) {
			setError(error.message);
		}
	};

	return (
		<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-7 bg-white lg:px-8">
			{error && <Alert message={error} />}
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<img alt="Your Company" src="https://municipaldemairena.com/wp-content/uploads/2020/10/cropped-ColorSinFondo.png" className="mx-auto h-28 w-auto" />
				<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">BMM ASISTENCIA</h2>
			</div>

			<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
				<form onSubmit={handleSubmit} method="POST" className="space-y-6">
					<div>
						<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
							Email
						</label>
						<div className="mt-2">
							<input
								id="email"
								name="email"
								type="email"
								required
								autoComplete="email"
								onChange={handleChange}
								className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div>
						<div className="flex items-center justify-between">
							<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
								Contraseña
							</label>
							<div className="text-sm">
								<button onClick={handleResetPassword} className="font-semibold text-slate-600 hover:text-slate-500">
									He olvidado mi contraseña
								</button>
							</div>
						</div>
						<div className="mt-2">
							<input
								id="password"
								name="password"
								type="password"
								required
								autoComplete="current-password"
								onChange={handleChange}
								className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div>
						<button
							type="submit"
							className="flex w-full justify-center rounded-md bg-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
						>
							Acceder
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}
