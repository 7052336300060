import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import { useNavigate } from "react-router-dom";

function Scanner() {
  const navigate = useNavigate()

	const scanner = useRef();
	const videoEl = useRef(null);
	const qrBoxEl = useRef(null);
	const [qrOn, setQrOn] = useState(true);

	// Success
	const onScanSuccess = (result) => {

		scanner.current?.stop()
    navigate("/assistance", {state: result})
	};

	// Fail
	const onScanFail = (err) => {
		console.log(err);
	};

	useEffect(() => {
		if (videoEl?.current && !scanner.current) {
			scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
				onDecodeError: onScanFail,
				preferredCamera: "environment",
				highlightScanRegion: true,
				highlightCodeOutline: true,
				overlay: qrBoxEl?.current || undefined
			});

			// 🚀 Start QR Scanner
			scanner?.current
				?.start()
				.then(() => setQrOn(true))
				.catch((err) => {
					if (err) setQrOn(false);
				});
		}

		return () => {
			if (!videoEl?.current) {
				scanner?.current?.stop();
			}
		};
	}, []);
	return (
		<div className="qr-reader mt-30">
			{/* QR */}
			<video ref={videoEl}></video>
			<div ref={qrBoxEl} className="qr-box">
				{/* <img src={QrFrame} alt="Qr Frame" width={256} height={256} className="qr-frame" /> */}
			</div>
		</div>
	);
}

export default Scanner;
