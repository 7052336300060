import React from "react";
import { Route, Routes } from "react-router-dom";
import { Login } from "./components/Login";
import { Register } from "./components/Register";
import { Home } from "./components/Home";
import { ProtectedRoute } from "./components/ProtectedRoute";

import { AuthProvider } from "./context/AuthContext";
import Navbar from "./components/Navbar";
import UserCreation from "./components/UserCreation";
import UserDetail from "./components/UserDetail";
import Scanner from "./components/Scanner";
import HandleAssistance from "./components/HandleAssistance";
import Settings from "./components/Settings";

function App() {
  return (
    <div className="bg-slate-300 flex h-screen">
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Navbar />
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/newteacher"
            element={
              <ProtectedRoute>
                <Navbar />
                <UserCreation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:email"
            element={
              <ProtectedRoute>
                <Navbar />
                <UserDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/scan"
            element={
              <ProtectedRoute>
                <Navbar />
                <Scanner />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assistance"
            element={
              <ProtectedRoute>
                <Navbar />
                <HandleAssistance />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Navbar />
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route path="/register" element={<Register />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
