import React, { useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { firestore } from "../firebase";
import { useNavigate } from "react-router-dom";

function UserCreation() {

  const navigate = useNavigate()

  const [user, setUser] = useState({
    nombre: '',
    apellidos: '',
    email: '',
    rol: ''
  })

  const handleChangeText = (name, value) => {
    setUser({ ...user, [name]: value });
  };

  const handlesubmit = (e) => {
    e.preventDefault()
    try {
      firestore.collection("users").doc(user.email).set({
        nombre: user.nombre,
        apellidos: user.apellidos,
        email: user.email,
        rol: user.rol
      })
    } catch (error) {
      console.log(error);
    } finally {
      navigate("/")
    }
    
  }

	return (
		<div className="p-2 content-center mx-auto">
			<form onSubmit={handlesubmit}>
				<div className="mb-5 bg-white p-10 w-80 rounded-lg shadow-lg">
      		<h1 className="font-bold uppercase text-xl">Crear usuario</h1>
					<label htmlFor="email" className="block my-2 text-sm font-medium text-gray-900">
						Rol
					</label>
					<Menu as="div" className="relative inline-block text-left w-full">
						<div>
							<MenuButton className="inline-flex capitalize w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
								<ChevronDownIcon aria-hidden="true" className="-ml-1 h-5 w-5 text-gray-400" />
								{user.rol !== '' ? user.rol : 'Opciones'}
							</MenuButton>
						</div>

						<MenuItems
							transition
							className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
						>
							<div className="py-1">
								<MenuItem>
									<button onClick={(value) => handleChangeText("rol", 'administrador')} className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
										Administrador
									</button>
								</MenuItem>
								<MenuItem>
									<button onClick={(value) => handleChangeText("rol", 'profesor')} className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
										Profesor
									</button>
								</MenuItem>
							</div>
						</MenuItems>
					</Menu>
					<label htmlFor="nombre" className="block mb-2 text-sm font-medium text-gray-900">
						Nombre
					</label>
					<input
						type="text"
						id="nombre"
						className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
						placeholder="..."
						required
            onChange={(value) => handleChangeText("nombre", value.target.value)}
					/>
					<label htmlFor="apellidos" className="block my-2 text-sm font-medium text-gray-900">
						Apellidos
					</label>
					<input
						type="text"
						id="apellidos"
						className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
						placeholder="..."
						required
            onChange={(value) => handleChangeText("apellidos", value.target.value)}
					/>
					<label htmlFor="email" className="block my-2 text-sm font-medium text-gray-900">
						Email
					</label>
					<input
						type="email"
						id="email"
						className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
						placeholder="nombre@municipaldemairena.com"
						required
            onChange={(value) => handleChangeText("email", value.target.value)}
					/>
          <button type="submit" className="my-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Guardar usuario</button>
				</div>
			</form>
		</div>
	);
}

export default UserCreation;
