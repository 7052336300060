import React, { useEffect, useState } from 'react'
import { onValue, ref, getDatabase, query, limitToFirst } from "firebase/database";
import { functions } from '../firebase';
import { DocumentChartBarIcon } from '@heroicons/react/24/outline';

function UserDetail() {
  const email = window.location.pathname.substring(1)
  const [user, setUser] = useState()
  const [picajes, setPicajes] = useState([])
  
  
  const getUserInfo = async () => {
    const getUser = functions.httpsCallable('getUserByEmail')
    await getUser(email).then((value) => {

      setUser(value.data)
      const db = getDatabase()
      const picajesRef = query(ref(db, "picajes/" + value.data.uid), limitToFirst(100))
      
      onValue(picajesRef, (snapshot) => {
        const data = snapshot.val();
        
        if (data) {
          setPicajes(Object.values(data).sort((a, b) => a.fecha - b.fecha));
        }
      })
    })
  }

  const exportCSV = () => {
    let csvContent = ["Fecha", "Clase", "Modo"].join(";") + "\r\n";
    picajes.forEach((picaje) => {
      const row = [picaje.fecha, picaje.aula, picaje.modo];
      csvContent += row.join(";") + "\r\n";
    })

    const BOM = "\uFEFF";
    const blob = new Blob([BOM + csvContent])
    const url = window.URL.createObjectURL(blob)
    const linkElement = document.createElement("a")
    linkElement.href = url;
    linkElement.setAttribute("download", user.email + ".csv" )
    linkElement.click()
  }

  useEffect(() => {
    getUserInfo()
  }, [user?.uid])
  
  return (
    <div className='my-20 mx-2 lg:mx-10 w-full lg:w-[90%] rounded-lg shadow-lg bg-white'>
      {user ? (
        <div>
          <h1 className='text-base lg:text-2xl font-bold m-5'>{user.displayName}</h1>
          <div className='flex justify-end mr-20'>
            <button onClick={exportCSV} className='flex items-center'>
              <DocumentChartBarIcon height={20} width={20} />CSV
            </button>
          </div>
          {picajes.length ? (
            <table className='table-auto w-full text-left mx-3'>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Aula</th>
                  <th>Modo</th>
                </tr>
              </thead>
              <tbody>
                {picajes.map((picaje) => {
                  return (
                    <tr key={picaje.fecha}>
                      <td>{picaje.fecha}</td>
                      <td>{picaje.aula}</td>
                      <td>{picaje.modo.toUpperCase()}</td>
                    </tr>
                  )
                })}

              </tbody>
            </table>
          ) : <p className='ml-3 font-bold text-red-500'>No existen registros</p>}
        </div>
      ) : <p>Loading</p>}
    </div>
  )
}

export default UserDetail