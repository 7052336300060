import React, { useEffect, useState } from "react";
import { firestore } from "../firebase";
import { useAuth } from "../context/AuthContext";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function Settings() {
	const { user } = useAuth();

	const [currentUser, setCurrentUser] = useState();
  const [alert, setAlert] = useState(false)

	useEffect(() => {
		firestore
			.collection("users")
			.doc(user.email)
			.get()
			.then((value) => {
				const object = value.data();
				setCurrentUser(object);
			})
			.catch((e) => {
			});
	}, [user]);

	const sendResetPassword = () => {
		const auth = getAuth();
		sendPasswordResetEmail(auth, user.email)
			.then(() => {
				setAlert(true)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<div className="mt-20 w-full">
      {alert && (
        <div className="absolute right-3 max-w-xs shadow-lg shadow-slate-800 p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50" role="alert">
				  <span className="font-semibold">Correo enviado!</span> Revisa tu bandeja de entrada. Encontrarás el enlace para resetear tu contraseña
          <button onClick={() => setAlert(false)} className="absolute top-2 right-2"><XMarkIcon height={20} width={20} /></button>
			  </div>
      )}
			
      {currentUser ? (<div className="bg-white mx-3 h-full rounded-lg shadow-lg">
				<h1 className="uppercase font-bold p-5">Ajustes</h1>

				<div className="mx-5 flex flex-col">
					<label className="font-bold">Nombre</label>
					<input disabled value={currentUser.nombre} className="text-gray-600" />
					<label className="font-bold">Apellidos</label>
					<input disabled value={currentUser.apellidos} className="text-gray-600" />
					<label className="font-bold">Email</label>
					<input disabled value={currentUser.email} className="text-gray-600" />
					<label className="font-bold">Rol</label>
					<input disabled value={currentUser.rol} className="text-gray-600 capitalize" />
					<hr className="mt-3" />
				</div>

				<h1 className="uppercase font-bold p-5">Resetear contraseña</h1>
				<button className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 ml-5" onClick={() => sendResetPassword()}>
					<ExclamationTriangleIcon className="mr-2" height={15} width={15} />
					Enviar email de reseteo de contraseña
				</button>
			</div>) : <p>Loading</p>}
		</div>
	);
}
